import React, {
  useEffect,
  useState,
} from "react";

import { useHolodeck } from "../Holodeck/Holodeck";

import PresentParticipantListSection from "./PresentParticipantListSection";

import { getOneStudentById } from "../../../services/datastore";
import { formatDateString } from "../../../services/time";

import "./presentParticipantList.scss";

// Import the sound file
import joinSound from "../../../sounds/student-joining-sound.wav";

/**
 * Displays name of all participants present in the room, sorted by treatment
 * type. Also shows if participant is audible (broadcast-enabled and unmuted)
 * or not, and highlights current loudest participant (Daily's concept of
 * "active speaker")
 *
 * @returns
 */
const PresentParticipantList = () => {

  /*
   * HOOKS
   */

  // Get participant info from Holodeck
  const {
    allParticipants,
    loudestParticipant,
    getParticipantName,
    getParticipantTreatmentType,
  } = useHolodeck();

  /*
   * STATE
   */

  // treatmentType-keyed object with arrays of student names and mute status
  const [
    sortedParticipants,
    setSortedParticipants,
  ] = useState( null );

  // Boolean indicating if this list should be shown or hidden
  const [ showList, setShowList ] = useState( true );

  // Map to store fetched student info
  const [ allStudentInfo, setAllStudentInfo ] = useState( {} );

  // State to track if the sound has been played
  const [soundPlayed, setSoundPlayed] = useState(false);

  /*
   * USE EFFECT
   */

  // When participants change, update the displayed list
  useEffect(() => {

    // Use reducer to split allParticipants into object of treatmentType-keyed arrays of participant names and audio status (Daily mute concept)
    const sortedParticipantsSimplified = allParticipants?.reduce(
      ( returnObject, thisParticipant ) => {

        // Get this participant's name and treatment type
        const name = thisParticipant.local
          ? `(you) ${getParticipantName( thisParticipant )}`
          : getParticipantName( thisParticipant );

        // Check whether or not this participant is muted (Daily's concept)
        const { audio } = thisParticipant;

        // Get participant treatment type
        const treatmentType = getParticipantTreatmentType( thisParticipant );

        // Check if this participant is the loudest participant in the room
        const isLoudest = loudestParticipant?.session_id === thisParticipant.session_id;

        // Check if the return object already has a key for this treatment type
        if ( returnObject[ treatmentType ] ) {

          // If the key already exists, add the student's info to it
          returnObject[ treatmentType ].push({
            name,
            audio,
            isLoudest,
            userId: thisParticipant.user_id,
          });
        }
        else {

          // If the key doesn't already exist, initialize it with this student's info
          returnObject[ treatmentType ] = [{
            name,
            audio,
            isLoudest,
            userId: thisParticipant.user_id,
          }];
        }

        // Update returnObject for next pass of reducer
        return returnObject;
      },
      {},
    );

    // Set the updated list in state
    setSortedParticipants( sortedParticipantsSimplified );

  }, [
    allParticipants,
    loudestParticipant,
  ]);

  // When participants change, update allStudentInfo
  useEffect(() => {
    if (allParticipants) {
      allParticipants.forEach(async (participant) => {
        // If we haven't fetched info for the participant and they're a student
        if ( !allStudentInfo[participant.user_id] 
          && !participant.user_name?.startsWith("instructor-")) {
          
          // Fetch student info
          const studentInfo = await getOneStudentById(participant.user_id, ["student_type", "studentCreatedAtUTC", "VIP_registration", "level", "drills_promoted", "drills_total"]);
          
          // Set fetched info in map keyed by user id
          const updatedAllStudentInfo = allStudentInfo;
          updatedAllStudentInfo[participant.user_id] = {
            studentType: studentInfo.student_type,
            studentAccountCreated: studentInfo.studentCreatedAtUTC ? studentInfo.studentCreatedAtUTC.substring(0, 10) : formatDateString(studentInfo.VIP_registration, "iso"),
            studentLevel: studentInfo.level,
            studentDrillsPromoted: studentInfo.drills_promoted,
            studentDrillsTotal: studentInfo.drills_total,
          };
          
          setAllStudentInfo(updatedAllStudentInfo);
        }
      });
    } 
  }, [ allParticipants ]);

  // Play sound when the first non-instructor student joins
  useEffect(() => {
    if (allParticipants) {
      const nonInstructorParticipants = allParticipants.filter(participant => 
        !participant.user_name?.startsWith("instructor-"),
      );

      if (nonInstructorParticipants.length === 1 && !soundPlayed) {
        const audio = new Audio(joinSound);
        audio.play();
        setSoundPlayed(true); // Set soundPlayed to true after playing the sound
      } 
      else if (nonInstructorParticipants.length === 0 && soundPlayed) {
        setSoundPlayed(false); // Reset soundPlayed to false when no non-instructor participants are present
      }
    }
  }, [allParticipants, soundPlayed]);

  /*
   * RENDER
   */

  // Helper function to display present participants while maintaining a set order
  const renderParticipants = ( participantsByTreatmentType ) => {

    // If we don't have any participants, we shouldn't render anything!
    if (
      !participantsByTreatmentType
      || !Object.keys( participantsByTreatmentType ).length
    ) {
      return null;
    }

    // This array is in the order that we want the participant list to be populated, with treatmentTypes that aren't represented in the room filtered out
    const presentTreatmentTypes = [
      "INSTRUCTOR",
      "SPEAKER",
      "CANDIDATE-SPEAKER",
      "OBSERVER",
    ].filter( (t) => Object.keys( participantsByTreatmentType ).includes(t) );

    // Render the lists in order and return the markup
    return presentTreatmentTypes.map( (treatmentType) => (
      <PresentParticipantListSection
        allStudentInfo={ allStudentInfo }
        key={ `section-${ treatmentType }` }
        treatmentType={ treatmentType }
        participants={ participantsByTreatmentType[ treatmentType ] }
      />
    ));
  };

  // Return markup!
  return (
    <aside
      className={`presentParticipantList ${
        showList ? "showList" : "hideList"
      }`}
    >
      <div className="presentParticipantList__inner">
        {// Render individual list of students in each treatmentType group
          renderParticipants( sortedParticipants )
        }
      </div>
      <button
        className="presentParticipantList__toggleButton"
        onClick={ () => setShowList( prev => !prev ) }
      >
        { showList ? "Hide Names" : "Show Names" }
      </button>
    </aside>
  );

};

export default PresentParticipantList;
